import React from "react";

const BannerSection = () => {
  return (
    <>
      <div
        className="p-0"
        style={{
          height: 'clamp("35vh", "50vw", "75vh")',
          backgroundImage: "url(assets/images/Contact-us-banner.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          backgroundRepeat: "center",
        }}
      ></div>
    </>
  );
};

export default BannerSection;
