import React from "react";

const ContactComponent = () => {
  return (
    <>
      <section className="" id="contact-form">
        <div className="container-fluid form-bg">
          <div className="container" data-aos="flip-up">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6 col-md-9 bg-light border rounded-5 p-sm-5 p-2 my-4">
                <form>
                  <div className="row px-3 p-md-1 h6">
                    <div className="col-12 pt-3 pb-3 pb-sm-5 text-center">
                      <h3 className="text-center">Get in touch today</h3>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipiscing elit
                        semper dalar elementum tempus hac tellus libero
                        accumsan.
                      </p>
                    </div>
                    <div className="mb-3 col-sm-6">
                      <label for="" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control p-md-3 p-3 rounded-pill"
                        placeholder="Pawan Kumar"
                        id=""
                        onkeydown="return /[a-z]/i.test(event.key)"
                      />
                    </div>

                    <div className="mb-3 col-sm-6">
                      <label for="" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control p-md-3 p-3 rounded-pill"
                        placeholder="Pawankrtest07@gmail.com"
                        id=""
                      />
                    </div>
                    <div className="mb-3 col-sm-6">
                      <label for="" className="form-label">
                        Phone
                      </label>
                      <input
                        type="text"
                        className="form-control p-md-3 p-3 rounded-pill"
                        placeholder="(123) 456 - 789"
                        id=""
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..?)\../g, '$1');"
                        minlength="10"
                        maxlength="10"
                      />
                    </div>

                    <div className="mb-3 col-sm-6">
                      <label for="" className="form-label">
                        Company
                      </label>
                      <input
                        type="email"
                        className="form-control p-md-3 p-3 rounded-pill"
                        placeholder="Figma"
                        id=""
                        onkeydown="return /[a-z]/i.test(event.key)"
                      />
                    </div>
                    <div className="mb-3 col-12">
                      <label for="" className="form-label mb-3">
                        Leave us a message
                      </label>
                      <textarea
                        className="form-control textarea"
                        placeholder="Please type your message here..."
                        name=""
                        id=""
                        cols="30"
                        rows="5"
                      ></textarea>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center justify-content-sm-start">
                    <button
                      type="submit"
                      className="btn btn-primary mt-2 p-3 px-3 ms-sm-3 ms-md-1 mb-3 mb-sm-1 px-sm-4 py-sm-3 rounded-pill"
                    >
                      Send message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactComponent;
