import React from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import Page404Component from "../components/Page404Component";

const Page404 = () => {
  return (
    <>
      <Header />
      <Page404Component />
      <Footer />
    </>
  );
};

export default Page404;
