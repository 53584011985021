import React from "react";

const Header = () => {
  return (
    <>
      <header>
        <div
          id="top-modal"
          className="d-none align-items-baseline bg-light-blue d-md-flex justify-content-center p-3 px-4"
        >
          <div className="d-flex flex-grow-1 align-items-baseline gap-3 justify-content-center">
            <p className="m-0">
              Get 20% OFF on your first order. Subscribe to our newsletter and
              get your coupon!
            </p>

            <button
              type="button"
              className="btn btn-round btn-primary px-4 py-3"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              Subscribe
            </button>

            <div
              className="modal fade"
              id="staticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg-light">
                  <div className="modal-header bg-light border-0 pb-0">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div
                    data-aos="fade-right"
                    data-aos-duration="500"
                    data-aos-delay="100"
                    className="bg-transparent rounded-5 p-sm-3 p-2"
                  >
                    <form>
                      <div className="row px-2 px-md-3 p-md-1">
                        <div className="mb-1 mb-sm-2 px-1 px-sm-2 mb-md-3">
                          <label for="" className="form-label h6 ps-1">
                            Name
                          </label>
                          <input
                            type="text"
                            className="form-control p-sm-2 p-md-3 rounded-pill"
                            placeholder="Pawan Kumar"
                            id=""
                            onkeydown="return /[a-z]/i.test(event.key)"
                          />
                        </div>

                        <div className="mb-1 mb-sm-2 px-1 px-sm-2 mb-md-3">
                          <label for="" className="form-label h6 ps-1">
                            Phone
                          </label>
                          <input
                            type="text"
                            className="form-control p-sm-2 p-md-3 rounded-pill"
                            placeholder="(123) 456 - 789"
                            id=""
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..?)\../g, '$1');"
                            minlength="10"
                            maxlength="10"
                          />
                        </div>

                        <div className="mb-1 mb-sm-2 px-1 px-sm-2 mb-md-3">
                          <label for="" className="form-label h6 ps-1">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control p-sm-2 p-md-3 rounded-pill"
                            placeholder="Pawankrtest07@gmail.com"
                            id=""
                          />
                        </div>

                        <div className="mb-1 mb-sm-2 px-1 px-sm-2 mb-md-3">
                          <label for="" className="form-label h6 ps-1">
                            City
                          </label>
                          <input
                            type="text"
                            className="form-control p-sm-2 p-md-3
                        rounded-pill"
                            placeholder="Bhopal"
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-center">
                        <button className="btn btn-round btn-primary p-2 px-3 px-md-4 py-md-3">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button className="bg-transparent close-btn border-0">
            <i className="fa-solid fa-xmark fs-3 my-auto"></i>
          </button>
        </div>

        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <a className="navbar-brand m-0" href="index.html">
              <img src="assets/images/logo-png.png" className="" alt="" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon m-0 p-3"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto gap-2 mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="">
                    Home
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Company
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="About-us.html">
                        About
                      </a>
                    </li>
                    <hr />
                    <li>
                      <a className="dropdown-item" href="address.html">
                        Office Address
                      </a>
                    </li>
                    <hr />
                    <li>
                      <a className="dropdown-item" href="team-member.html">
                        Team Member
                      </a>
                    </li>
                    <hr />
                    <li>
                      <a className="dropdown-item" href="company-growth.html">
                        Company Growth
                      </a>
                    </li>
                    <hr />
                    <li>
                      <a className="dropdown-item" href="company-present.html">
                        Company Presence
                      </a>
                    </li>
                    <hr />
                    <li>
                      <a className="dropdown-item" href="company-history.html">
                        Company History
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Agriculture
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="#">
                        Tillage
                      </a>
                    </li>
                    <hr />
                    <li>
                      <a className="dropdown-item" href="#">
                        Planting-Sowing
                      </a>
                    </li>
                    <hr />
                    <li>
                      <a className="dropdown-item" href="#">
                        Crop Care
                      </a>
                    </li>
                    <hr />
                    <li>
                      <a className="dropdown-item" href="#">
                        Harwesting
                      </a>
                    </li>
                    <hr />
                    <li>
                      <a className="dropdown-item" href="#">
                        Post Harwesting
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Construction
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="#">
                        Loader Tracker Mounted
                      </a>
                    </li>
                    <hr />
                    <li>
                      <a className="dropdown-item" href="#">
                        Backhoe Tracker Mounted
                      </a>
                    </li>
                    <hr />
                    <li>
                      <a className="dropdown-item" href="#">
                        Mini Excavator- Kubota
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    aria-current="page"
                    href="news&media.html"
                  >
                    News & Media
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    aria-current="page"
                    href="contact.html"
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <div className="progress-bar-container">
        <div className="progress-bar"></div>
      </div>
    </>
  );
};

export default Header;
