import React from "react";

const MainSection = () => {
  return (
    <>
      <section className="pt-0 pt-sm-4">
        <div
          className="container"
          data-aos="fade-right"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <h1>
            Agricultural Construction <br />
            Execution of modern agricultural facilities
          </h1>
          <p className="text-center">
            Lorem ipsum dolor sit amet consectetur adipiscing eli mattis sit
            phasellus mollis sit aliquam sit nullam neque ultrices.
          </p>
          <div className="btn-container d-flex gap-3 justify-content-center">
            <button className="btn btn-round btn-primary p-3 px-4">
              Get Started <i className="fas ps-2 fa-arrow-right"></i>
            </button>
            <button className="btn btn-round btn-border p-3 px-4 fw-normal">
              Talk to Buy
            </button>
          </div>
        </div>
      </section>
      <section className="">
        <div
          className="container"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="100"
        >
          <img src="%PUBLIC_URL%/images/home-bg.png" className="w-100" alt="" />
        </div>
      </section>
    </>
  );
};

export default MainSection;
