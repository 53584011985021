import React from "react";

const Footer = () => {
  return (
    <>
      <section className="border-top border-2 pb-0">
        <div className="container">
          <footer className="">
            <div className="row pb-3 row-gap-3">
              <div className="col-sm-6 col-md-4 col-lg-4">
                <a href="index.html">
                  <img
                    src="assets/images/logo-png.png"
                    alt=""
                    className="w-75"
                    srcset=""
                  />
                </a>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipiscing elit aliquam
                  mauris sed ma
                </p>
              </div>

              <div className="col-6 col-md-4 col-lg-2">
                <h5>Company</h5>
                <ul className="nav flex-column">
                  <li className="nav-item mb-2">
                    <a href="About-us.html" className="nav-link p-0">
                      About
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="contact.html" className="nav-link p-0">
                      Contact
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="news&media.html" className="nav-link p-0">
                      News & Media
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="privacy-policy.html" className="nav-link p-0">
                      Privacy Policy
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="term-condition.html" className="nav-link p-0">
                      Term & Condition
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-6 col-md-4 col-lg-2">
                <h5>Agriculture</h5>
                <ul className="nav flex-column">
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0">
                      Tracker
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0">
                      Roller
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0">
                      Balers
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0">
                      Cultivator
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-6 col-md-4 col-lg-2">
                <h5>Construction</h5>
                <ul className="nav flex-column">
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0">
                      Tracker
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0">
                      Roller
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0">
                      Balers
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0">
                      Cultivator
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-6 col-md-4 col-lg-2">
                <h5>Social Handle</h5>
                <div className="row row-gap-2">
                  <div className="col-12">
                    <a href="" className="btn mx-1 mx-sm-2 px-3 py-2 btn-light">
                      <i className="fa-brands fs-5 fa-facebook-f"></i>
                    </a>
                    <a href="" className="btn mx-1 mx-sm-2 px-3 py-2 btn-light">
                      <i className="fa-brands fs-5 fa-twitter"></i>
                    </a>
                  </div>

                  <div className="col-12">
                    <a href="" className="btn mx-1 mx-sm-2 px-3 py-2 btn-light">
                      <i className="fa-brands fs-5 fa-instagram"></i>
                    </a>
                    <a href="" className="btn mx-1 mx-sm-2 px-3 py-2 btn-light">
                      <i className="fa-brands fs-5 fa-linkedin"></i>
                    </a>
                  </div>

                  <div className="col-12">
                    <a href="" className="btn mx-1 mx-sm-2 px-5 py-2 btn-light">
                      <i className="fa-brands fs-5 fa-youtube"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex pt-3 justify-content-center border-top border-2">
              <p className="text-center">
                Copyright © 2024 pawan_design | All Rights Reserved |
                <a className="text-light p" href="term-condition.html">
                  Terms and Conditions
                </a>
                |
                <a className="text-light p" href="term-condition.html">
                  Privacy Policy
                </a>
              </p>
            </div>
          </footer>
        </div>
      </section>
    </>
  );
};

export default Footer;
