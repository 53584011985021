import React from "react";

const OfficeAddress = () => {
  return (
    <>
      <section className="" id="office-location">
        <div className="container">
          <div className="row g-4">
            <div className="col-12" data-aos="fade-up" data-aos-duration="700">
              <div className="row">
                <div className="col-12 col-lg-6 col-md-10 h1-color text-center mx-auto">
                  <h3>Office Location</h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipiscing elit
                    semper dalar elementum tempus hac tellus libero accumsan.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-sm"
              data-aos="flip-left"
              data-aos-duration="500"
              data-aos-delay="500"
            >
              <div className="card card-body shadow text-center align-items-center h-100 rounded-4 py-4 px-3">
                <div className="mb-2">
                  <i className="fa fa-location-dot text-warning fs-1"></i>
                </div>

                <p className="">
                  Bhopal Address:Lambakheda, Berasiya Road, Bhopal, Madhya
                  Pradesh, India - 462038
                </p>
              </div>
            </div>

            <div
              className="col-sm"
              data-aos="flip-up"
              data-aos-duration="500"
              data-aos-delay="500"
            >
              <div className="card card-body shadow text-center align-items-center h-100 rounded-4 py-4 px-3">
                <div className="mb-2">
                  <i className="fa fa-location-dot text-warning fs-1"></i>
                </div>

                <p className="">
                  Morbi Address:G-15, Seven cera mall, Pipali-Bela road,
                  Morbi-2, Gujarat, India – 363642
                </p>
              </div>
            </div>

            <div
              className="col-sm"
              data-aos="flip-right"
              data-aos-duration="500"
              data-aos-delay="500"
            >
              <div className="card card-body shadow text-center align-items-center h-100 rounded-4 py-4 px-3">
                <div className="mb-2">
                  <i className="fa fa-location-dot text-warning fs-1"></i>
                </div>

                <p className="">
                  Indore Address:242/5, SDA Compound, Near Railway Crossing,
                  Dewas Naka, Indore, 452010
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OfficeAddress;
