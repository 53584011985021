import React from "react";

const OurValue = () => {
  return (
    <>
      <section className="pb-0">
        <div className="container">
          <h3
            className="text-center"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="100"
          >
            Our Value
          </h3>
          <p
            className="text-center"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="100"
          >
            Lorem ipsum dolor sit amet consectetur adipiscing elit semper <br />
            dalar elementum tempus hac tellus libero accumsan.
          </p>
          <div
            className="row text-center align-items-center"
            data-aos="fade-left"
            data-aos-duration="500"
            data-aos-delay="100"
          >
            <div className="col-6 col-sm-4 col-md">
              <div className="text-center align-items-center">
                <div className="mb-2">
                  <img src="assets/images/icon/OurValues 0.png" alt="" />
                </div>

                <p className="fw-bold text-primary m-0">Customer Centricity</p>
              </div>
            </div>
            <div className="col-6 col-sm-4 col-md">
              <div className="text-center align-items-center">
                <div className="mb-2">
                  <img src="assets/images/icon/OurValues 1.png" alt="" />
                </div>

                <p className="fw-bold text-primary m-0">Integrity</p>
              </div>
            </div>
            <div className="col-6 col-sm-4 col-md">
              <div className="text-center align-items-center">
                <div className="mb-2">
                  <img src="assets/images/icon/OurValues 2.png" alt="" />
                </div>

                <p className="fw-bold text-primary m-0">Quality</p>
              </div>
            </div>
            <div className="col-6 col-sm-4 col-md">
              <div className="text-center align-items-center">
                <div className="mb-2">
                  <img src="assets/images/icon/OurValues 3.png" alt="" />
                </div>

                <p className="fw-bold text-primary m-0">Innovation</p>
              </div>
            </div>
            <div className="col-6 col-sm-4 col-md">
              <div className="text-center align-items-center">
                <div className="mb-2">
                  <img src="assets/images/icon/OurValues 4.png" alt="" />
                </div>

                <p className="fw-bold text-primary m-0">Substainability</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurValue;
