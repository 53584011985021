import React from "react";

const ProductDetailComponent = () => {
  return (
    <>
      <section className="agri-card">
        <div className="container py-5">
          <div className="row p-2 gap-4">
            <div className="col-md-4" data-aos="zoom-in">
              <img
                src="./assets/images/Agri/disk-plough.png"
                alt=""
                className="img-fluid h-100"
              />
            </div>
            <div className="col-md-6 mx-auto" data-aos="fade-left">
              <h3 className="fs-3 fw-bold">Disc Plough</h3>
              <div className="plugs-social-icon d-flex gap-3 py-2">
                <i className="fa-brands fa-facebook-f px-3"></i>
                <i className="fa-brands fa-linkedin-in"></i>
                <i className="fa-brands fa-instagram"></i>
                <i className="fa-solid fa-share-nodes"></i>
              </div>
              <div className="">
                <p>
                  The disc plough is designed to work in all types of soil for
                  functions such as soil breaking, soil raising, soil turning
                  and soil mixing. It is used to open the new fields and to
                  process the stony areas. It can be used easily at rocky and
                  rooted areas.
                  <p>
                    Disc ploughs are a good choice for farmers who have stony or
                    compacted soil, as they can cut this soil without much
                    difficulty. Chisel ploughs: Chisel ploughs are a type of
                    tillage implement that uses a series of chisel-like blades
                  </p>
                </p>
              </div>
              <div className="row">
                <div className="col-4 border-end border-2 border-dark text-center">
                  <h3 className="text-dark">27+</h3>
                  <p className="">years of Experience</p>
                </div>

                <div className="col-4 border-end border-2 border-dark text-center">
                  <h3 className="text-dark">200+</h3>
                  <p className="">Disc Plough Availables</p>
                </div>
                <div className="col-4 text-center border-end border-end-0">
                  <h3 className="text-dark">1.5k+</h3>
                  <p className="">Client trust</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <h5 className="py-2">What are the advantages of a Disc Plough?</h5>
            <p className="">
              There are different types of models of a disc ploughs in the
              market, such as mounted disc plough and coaxial disc ploughs. Here
              we have mentioned a few advantages of suing a disc plough in the
              field. A disc plough works in all types of soil to break, turn and
              mix the soil. It is majorly used to prepare the new fields for
              farming and process the stony areas. Makes ploughing easier in
              rocky and rooted areas. Very useful in hard and dry trashy land
              conditions. Works best in areas where soil erosion is a major
              problem. Disc Plough can be operated easily despite heavy crop
              residue.
            </p>
            <ul className="px-5">
              <li>
                A disc plough works in all types of soil to break, turn and mix
                the soil.
              </li>
              <li>
                It is majorly used to prepare the new fields for farming and
                process the stony areas.
              </li>
              <li>Makes ploughing easier in rocky and rooted areas.</li>
              <li>Very useful in hard and dry trashy land conditions.</li>
              <li>
                Works best in areas where soil erosion is a major problem.
              </li>
              <li>
                Disc Plough can be operated easily despite heavy crop residue.
              </li>
            </ul>
          </div>
          <div className="row mt-4">
            <h5 className="">What is a Mould- Board Plough?</h5>
            <p>
              A mold-board plough a vital farm equipment in soil maintenance. It
              is majorly used to prepare the soil before sowing seeds for giving
              it a final structure. An MB plough also works best when you need
              to prepare the ground for grass seeding. It consists of a set of
              large curved bottom that is called a mould-board fixed to a frame.
            </p>
            <p>
              A mold-board plough a vital farm equipment in soil maintenance. It
              is majorly used to prepare the soil before sowing seeds for giving
              it a final structure. An MB plough also works best when you need
              to prepare the ground for grass seeding. It consists of a set of
              large curved bottom that is called a mould-board fixed to a frame.
            </p>
            <div className="text-center" data-aos="flip-up">
              <img
                src="./assets/images/Agri/tri-machine.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container" data-aos="fade-up">
          <div className="row d-flex justify-content-around">
            <div className="col-md-5">
              <div className="" data-aos="zoom-in">
                <iframe
                  className="rounded"
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/nUCt3GGWf5Q?si=3ePNbNajDVZV6rPY"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div
              className="col-md-6"
              data-aos="fade-left"
              data-aos-duration="500"
              data-aos-delay="500"
            >
              <h3>How to work Disc Plough</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipiscing elit semper
                dalar elementum tempus hac tellus libero accumsan. Lorem ipsum
                dolor sit amet consectetur adipiscing elit semper dalar
                elementum tempus hac tellus libero accumsan.{" "}
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipiscing elit semper
                dalar elementum tempus hac tellus libero accumsan.{" "}
              </p>
              <div className="text-center p-3 col-lg-4 col-6 col-sm-4 col-md-5 border rounded-5 text-primary border-primary">
                <a href="" className="pe-2 mb-0">
                  Watch Now
                </a>
                <i className="fa fa-arrow-right my-auto text-primary"></i>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductDetailComponent;
