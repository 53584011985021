import React from "react";

const Associate = () => {
  return (
    <>
      <section id="associate-with" className="">
        <div
          className="container-fluid pb-5"
          data-aos="fade-up"
          data-aos-duration="700"
        >
          <div className="row">
            <div className="col-12 col-lg-6 col-md-10 h1-color text-center mx-auto">
              <h3>Associated With</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipiscing elit semper
                dalar elementum tempus hac tellus libero accumsan.
              </p>
            </div>
          </div>

          <div className="container">
            <div className="swiper mySwiper">
              <div className="swiper-wrapper d-flex align-items-center">
                <div className="swiper-slide">
                  <div className="div">
                    <img
                      src="assets/images/google-logo.png"
                      alt=""
                      className="w-100"
                    />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="div">
                    <img
                      src="assets/images/youtube-logo.png"
                      alt=""
                      className="w-100"
                    />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="div">
                    <img
                      src="assets/images/fb-logo.png"
                      alt=""
                      className="w-100"
                    />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="div">
                    <img
                      src="assets/images/insta-logo.png"
                      alt=""
                      className="w-100"
                    />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="div">
                    <img
                      src="assets/images/webflow-logo.png"
                      alt=""
                      className="w-100"
                    />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="div">
                    <img
                      src="assets/images/google-logo.png"
                      alt=""
                      className="w-100"
                    />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="div">
                    <img
                      src="assets/images/youtube-logo.png"
                      alt=""
                      className="w-100"
                    />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="div">
                    <img
                      src="assets/images/fb-logo.png"
                      alt=""
                      className="w-100"
                    />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="div">
                    <img
                      src="assets/images/insta-logo.png"
                      alt=""
                      className="w-100"
                    />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="div">
                    <img
                      src="assets/images/webflow-logo.png"
                      alt=""
                      className="w-100"
                    />
                  </div>
                </div>
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Associate;
