import React from "react";

const Page404Component = () => {
  return (
    <>
      <div style={{ textAlign: "center", padding: "50px" }}>
        <h1>404 - Page Not Found</h1>
        <p>Sorry, the page you are looking for does not exist.</p>
      </div>
    </>
  );
};

export default Page404Component;