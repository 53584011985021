import React from "react";

const Team = () => {
  return (
    <>
      <section className="">
        <div className="container">
          <h3
            className="text-center"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="100"
          >
            Our Core Team
          </h3>
          <p
            className="text-center"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="100"
          >
            Lorem ipsum dolor sit amet consectetur adipiscing elit semper <br />
            dalar elementum tempus hac tellus libero accumsan.
          </p>
          <div className="row pt-3 row-gap-5 pt-md-5 text-center align-items-center">
            <div
              className="col-md-4"
              data-aos="zoom-in"
              data-aos-duration="500"
              data-aos-delay="100"
            >
              <div className="card pt-2 border-0 bg-light position-relative overflow-visible">
                <div className="card-header bg-transparent border-0">
                  <img
                    src="assets/images/icon/BG.png"
                    className="card-img rounded-circle border border-white border-5 position-absolute"
                    style={{ width: "70px", left: "40%", top: "-35px" }}
                    alt=""
                  />
                </div>
                <div className="card-body z-3">
                  <h5 className="card-title text-primary">Shivam Kumar</h5>
                  <h6 className="card-title text-gray">CEO & Founder</h6>
                  <p>
                    “A visual guide could be a wireframe, creative composition,
                    or information architecture. A device that enables
                    collaboration will lessen the chance of work having to be
                    completely redone.”
                  </p>
                </div>
                <img
                  src="assets/images/icon/bottom-right-border.png"
                  className="bottom-right-border"
                  alt=""
                />
              </div>
            </div>
            <div
              className="col-md-4"
              data-aos="zoom-in"
              data-aos-duration="500"
              data-aos-delay="100"
            >
              <div className="card pt-2 bg-primary overflow-visible">
                <div className="card-header bg-transparent border-0">
                  <img
                    src="assets/images/icon/BG.png"
                    className="card-img rounded-circle border border-white border-5 position-absolute"
                    style={{ width: "70px", left: "40%", top: "-35px" }}
                    alt=""
                  />
                </div>
                <div className="card-body z-3">
                  <h5 className="card-title text-white">Shivam Kumar</h5>
                  <h6 className="card-title text-secondary">CEO & Founder</h6>
                  <p className="text-white">
                    “A visual guide could be a wireframe, creative composition,
                    or information architecture. A device that enables
                    collaboration will lessen the chance of work having to be
                    completely redone.”
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-md-4"
              data-aos="zoom-in"
              data-aos-duration="500"
              data-aos-delay="100"
            >
              <div className="card pt-2 border-0 bg-light position-relative overflow-visible">
                <div className="card-header bg-transparent border-0">
                  <img
                    src="assets/images/icon/BG.png"
                    className="card-img rounded-circle border border-white border-5 position-absolute"
                    style={{ width: "70px", left: "40%", top: "-35px" }}
                    alt=""
                  />
                </div>
                <div className="card-body z-3">
                  <h5 className="card-title text-primary">Shivam Kumar</h5>
                  <h6 className="card-title text-gray">CEO & Founder</h6>
                  <p>
                    “A visual guide could be a wireframe, creative composition,
                    or information architecture. A device that enables
                    collaboration will lessen the chance of work having to be
                    completely redone.”
                  </p>
                </div>
                <img
                  src="assets/images/icon/bottom-right-border.png"
                  className="bottom-right-border"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Team;
