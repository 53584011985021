import React from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import BannerSection from "../components/BannerSection";
import ProductDetailComponent from "../components/ProductDetailComponent";

const ProductDetail = () => {
  return (
    <>
      <Header />
      <BannerSection />
      <ProductDetailComponent />
      <Footer />
    </>
  );
};

export default ProductDetail;
