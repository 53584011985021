import React from "react";

const Contact = () => {
  return (
    <>
      <section className="">
        <div className="container">
          <div className="row flex-column-reverse flex-md-row p-3 gap-4">
            <div
              data-aos="fade-right"
              data-aos-duration="500"
              data-aos-delay="100"
              className="col-lg-6 bg-light rounded-5 p-sm-3 py-3 p-2 py-md-5"
            >
              <form>
                <div className="row px-2 px-md-3 p-md-1">
                  <div className="mb-1 mb-sm-2 px-1 px-sm-2 mb-md-3 col-sm-6">
                    <label for="" className="form-label h6 ps-1">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control p-sm-2 p-md-3 rounded-pill"
                      placeholder="Pawan Kumar"
                      id=""
                      onkeydown="return /[a-z]/i.test(event.key)"
                    />
                  </div>

                  <div className="mb-1 mb-sm-2 px-1 px-sm-2 mb-md-3 col-sm-6">
                    <label for="" className="form-label h6 ps-1">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control p-sm-2 p-md-3 rounded-pill"
                      placeholder="Pawankrtest07@gmail.com"
                      id=""
                    />
                  </div>
                  <div className="mb-1 mb-sm-2 px-1 px-sm-2 mb-md-3 col-sm-6">
                    <label for="" className="form-label h6 ps-1">
                      Phone
                    </label>
                    <input
                      type="text"
                      className="form-control p-sm-2 p-md-3 rounded-pill"
                      placeholder="(123) 456 - 789"
                      id=""
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..?)\../g, '$1');"
                      minlength="10"
                      maxlength="10"
                    />
                  </div>

                  <div className="mb-1 mb-sm-2 px-1 px-sm-2 mb-md-3 col-sm-6">
                    <label for="" className="form-label h6 ps-1">
                      Company
                    </label>
                    <input
                      type="text"
                      className="form-control p-sm-2 p-md-3
                  rounded-pill"
                      placeholder="Figma"
                    />
                  </div>
                  <div className="mb-1 mb-sm-2 px-1 px-sm-2 mb-md-3 col-12">
                    <label for="" className="form-label h6 ps-1 mb-2">
                      Leave us a message
                    </label>
                    <textarea
                      className="form-control textarea border-0"
                      placeholder="Please type your message here..."
                      name=""
                      id=""
                      cols="30"
                      rows="5"
                    ></textarea>
                  </div>
                </div>

                <button className="btn btn-round btn-primary mt-2 p-2 px-3 px-md-4 py-md-3">
                  Send message
                </button>
              </form>
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="500"
              data-aos-delay="100"
              className="col-lg-5 px-3"
            >
              <h3>Get in touch today</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipiscing elit nulla
                adipiscing tincidunt interdum tellus du.
              </p>

              <div className="row row-cols-1 row-gap-4">
                <div className="bg-light rounded-3 d-flex align-items-center gap-2 p-2">
                  <img
                    src="assets/images/icon/material-symbols_call.png"
                    className="form-icon"
                    alt=""
                  />
                  <p className="m-0">Phone: +91 9981997755</p>
                </div>
                <div className="bg-light rounded-3 d-flex align-items-center gap-2 p-2">
                  <img
                    src="assets/images/icon/ant-design_mail-twotone.png"
                    className="form-icon"
                    alt=""
                  />
                  <p className="m-0">Email: info@samarthagri.in</p>
                </div>
                <div className="bg-light rounded-3 d-flex align-items-center gap-2 p-2">
                  <img
                    src="assets/images/icon/fluent_location-24-filled.png"
                    className="form-icon"
                    alt=""
                  />
                  <p className="m-0">
                    Bhopal Address:Lambakheda, Berasiya Road, Bhopal, Madhya
                    Pradesh, India - 462038
                  </p>
                </div>
                <div className="bg-light rounded-3 d-flex align-items-center gap-2 p-2">
                  <img
                    src="assets/images/icon/fluent_location-24-filled.png"
                    className="form-icon"
                    alt=""
                  />
                  <p className="m-0">
                    Indore Address:242/5, SDA Compound, Near Railway Crossing,
                    Dewas Naka, Indore, 452010
                  </p>
                </div>
                <div className="bg-light rounded-3 d-flex align-items-center gap-2 p-2">
                  <img
                    src="assets/images/icon/fluent_location-24-filled.png"
                    className="form-icon"
                    alt=""
                  />
                  <p className="m-0">
                    Morbi Address:G-15, Seven cera mall, Pipali-Bela road,
                    Morbi-2, Gujarat, India – 363642
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
