import React from "react";

const Counter = () => {
  return (
    <>
      <section className="">
        <div
          className="container-fluid h1-color"
          data-aos="fade-left"
          data-aos-duration="500"
        >
          <div className="container">
            <div className="row shadow text-center align-items-center rounded-4 py-sm-4 py-3 px-3">
              <div className="col-md-3 col-6">
                <div className="text-center align-items-center h-100 rounded-4 py-3">
                  <div className="mb-2">
                    <i className="fa fa-check-circle text-warning fs-1"></i>
                  </div>

                  <p className="fw-bold text-primary mt-3">
                    12 Year Experience
                  </p>
                </div>
              </div>

              <div className="col-md-3 col-6">
                <div className="text-center align-items-center h-100 rounded-4 py-3">
                  <div className="mb-2">
                    <i className="fa fa-earth text-warning fs-1"></i>
                  </div>

                  <p className="fw-bold text-primary mt-3">08 Country</p>
                </div>
              </div>

              <div className="col-md-3 col-6">
                <div className="text-center align-items-center h-100 rounded-4 py-3">
                  <div className="mb-2">
                    <i className="fa fa-users text-warning fs-1"></i>
                  </div>

                  <p className="fw-bold text-primary mt-3">1500 Farmer</p>
                </div>
              </div>

              <div className="col-md-3 col-6">
                <div className="text-center align-items-center h-100 rounded-4 py-3">
                  <div className="mb-2">
                    <i className="fa fa-users text-warning fs-1"></i>
                  </div>

                  <p className="fw-bold text-primary mt-3">180 Deelar</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Counter;
