import React from "react";

const BlogComponent = () => {
  return (
    <>
      <section className="p-0">
        <div className="container">
          <h3
            className="text-center"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="100"
          >
            News & Media
          </h3>
          <p
            className="text-center"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="100"
          >
            Lorem ipsum dolor sit amet consectetur adipiscing elit <br />
            volutpat gravida malesuada quam commodo id integer nam.
          </p>

          <div className="row row-gap-2 row-gap-md-5">
            <div className="col-md-4 mt-2">
              <div className="card mx-auto border-0" data-aos="zoom-in">
                <img
                  src="./assets/images/Agri/Card (1).png"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body border-0">
                  <a
                    href=""
                    className="d-flex justify-content-between align-items-center"
                  >
                    <p>
                      Agriculture . <span>3 days</span>
                    </p>
                    <div className="">
                      <i className="fa-brands fa-whatsapp pe-"></i>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-dark mb-1"
                        style={{ height: "20px", width: "25px" }}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <circle cx="18" cy="5" r="3"></circle>
                        <circle cx="6" cy="12" r="3"></circle>
                        <circle cx="18" cy="19" r="3"></circle>
                        <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
                        <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
                      </svg>
                    </div>
                  </a>
                  <div>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Eligendi assumenda quis delectus iste libero dolores
                      exercitationem magnam accusantium porro eos expedita
                      minima, debitis voluptatem. Temporibus velit id pariatur
                      ut quia?
                    </p>
                  </div>
                  <div className="">
                    <a href="" className="pe-2 text-primary mb-0">
                      Read more <i className="fa fa-arrow-right ms-2"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-2">
              <div className="card mx-auto border-0" data-aos="zoom-in">
                <img
                  src="./assets/images/Agri/Card (2).png"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body border-0">
                  <a
                    href=""
                    className="d-flex justify-content-between align-items-center"
                  >
                    <p>
                      Agriculture . <span>3 days</span>
                    </p>
                    <div className="">
                      <i className="fa-brands fa-whatsapp pe-"></i>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-dark mb-1"
                        style={{ height: "20px", width: "25px" }}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <circle cx="18" cy="5" r="3"></circle>
                        <circle cx="6" cy="12" r="3"></circle>
                        <circle cx="18" cy="19" r="3"></circle>
                        <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
                        <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
                      </svg>
                    </div>
                  </a>
                  <div>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Eligendi assumenda quis delectus iste libero dolores
                      exercitationem magnam accusantium porro eos expedita
                      minima, debitis voluptatem. Temporibus velit id pariatur
                      ut quia?
                    </p>
                  </div>
                  <div className="">
                    <a href="" className="pe-2 text-primary mb-0">
                      Read more <i className="fa fa-arrow-right ms-2"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-2">
              <div className="card mx-auto border-0" data-aos="zoom-in">
                <img
                  src="./assets/images/Agri/card.png"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body border-0">
                  <a
                    href=""
                    className="d-flex justify-content-between align-items-center"
                  >
                    <p>
                      Agriculture . <span>3 days</span>
                    </p>
                    <div className="">
                      <i className="fa-brands fa-whatsapp pe-"></i>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-dark mb-1"
                        style={{ height: "20px", width: "25px" }}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <circle cx="18" cy="5" r="3"></circle>
                        <circle cx="6" cy="12" r="3"></circle>
                        <circle cx="18" cy="19" r="3"></circle>
                        <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
                        <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
                      </svg>
                    </div>
                  </a>
                  <div>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Eligendi assumenda quis delectus iste libero dolores
                      exercitationem magnam accusantium porro eos expedita
                      minima, debitis voluptatem. Temporibus velit id pariatur
                      ut quia?
                    </p>
                  </div>
                  <div className="">
                    <a href="" className="pe-2 text-primary mb-0">
                      Read more <i className="fa fa-arrow-right ms-2"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex pt-3 justify-content-center">
            <button className="btn btn-round btn-border p-3 px-4 fw-normal">
              See all <i className="fas ps-2 fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogComponent;
