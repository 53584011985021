import React from "react";
import Header from "../components/layout/Header";
import BannerSection from "../components/BannerSection";
import BlogDetailComponent from "../components/BlogDetailComponent";
import Subscribe from "../components/Subscribe";
import Footer from "../components/layout/Footer";

const BlogDetail = () => {
  return (
    <>
      <Header />
      <BannerSection />
      <BlogDetailComponent />
      <Subscribe />
      <Footer />
    </>
  );
};

export default BlogDetail;
