import React from "react";

const OurAim = () => {
  return (
    <>
      <section className="" id="Category">
        <div className="container">
          <h3
            className="text-center"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="100"
          >
            Our Aim
          </h3>
          <p
            className="text-center"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="100"
          >
            Lorem ipsum dolor sit amet consectetur adipiscing elit semper <br />
            dalar elementum tempus hac tellus libero accumsan.
          </p>
          <div className="row flex-column-reverse flex-md-row row-gap-4 pt-3">
            <div
              className="col-md-6"
              data-aos="fade-right"
              data-aos-duration="500"
              data-aos-delay="100"
            >
              <ul
                className="nav nav-pills d-flex justify-content-center gap-3 mb-3"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="btn btn-round px-4 py-3 nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Agriculture
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="btn btn-round px-4 py-3 nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Construction
                  </button>
                </li>
              </ul>

              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <p className="mt-3 m-0">
                    Lorem ipsum dolor sit amet consectetur adipiscing elit
                    volutpat gravida malesuada quam commodo id integer nam.
                  </p>
                  <p className="m-0">
                    Lorem ipsum dolor sit amet consectetur adipiscing elit
                    volutpat gravida malesuada quam commodo id integer nam.
                  </p>
                  <p className="m-0">
                    Lorem ipsum dolor sit amet consectetur adipiscing elit
                    volutpat gravida malesuada quam commodo id integer nam.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipiscing elit
                    volutpat gravida malesuada quam commodo id integer nam.
                  </p>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <p className="mt-3 m-0">
                    Lorem ipsum dolor sit amet consectetur adipiscing elit
                    volutpat gravida malesuada quam commodo id integer nam.
                  </p>
                  <p className="m-0">
                    Lorem ipsum dolor sit amet consectetur adipiscing elit
                    volutpat gravida malesuada quam commodo id integer nam.
                  </p>
                  <p className="m-0">
                    Lorem ipsum dolor sit amet consectetur adipiscing elit
                    volutpat gravida malesuada quam commodo id integer nam.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipiscing elit
                    volutpat gravida malesuada quam commodo id integer nam.
                  </p>
                </div>
              </div>
              <button className="btn btn-round btn-border p-3 px-4 fw-normal">
                Read More <i className="fas ps-2 fa-arrow-right"></i>
              </button>
            </div>
            <div
              className="col-md-6"
              data-aos="zoom-in"
              data-aos-duration="500"
              data-aos-delay="100"
            >
              <img
                src="assets/images/Vision-img.jpg"
                className="rounded-5 img-fluid w-100"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurAim;
