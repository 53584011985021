import React from "react";

const CategoryProduct = () => {
  return (
    <>
      <section id="Category" className="p-0">
        <div className="container">
          <h3
            className="text-center"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="100"
          >
            Category
          </h3>
          <p
            className="text-center"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="100"
          >
            Lorem ipsum dolor sit amet consectetur adipiscing elit <br />
            volutpat gravida malesuada quam commodo id integer nam.
          </p>
          <ul
            className="nav nav-pills d-flex justify-content-center gap-3 mb-3"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className="btn btn-round px-4 py-3 nav-link active"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                Agriculture
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="btn btn-round px-4 py-3 nav-link"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                Construction
              </button>
            </li>
          </ul>

          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <div className="row row-gap-2 row-gap-sm-3 row-gap-md-4 pt-2 pt-sm-3 pt-md-4">
                <div className="col-md-4 mt-2 fruite" data-aos="zoom-in">
                  <div className="card mx-auto rounded-4 fruite-item">
                    <div className="fruite-img">
                      <img
                        src="./assets/images/sflo.jpg"
                        className="card-img-top"
                        alt="..."
                      />
                    </div>
                    <div className="card-body">
                      <a
                        href=""
                        className="d-flex justify-content-between align-items-center"
                      >
                        <h6>Agriculture</h6>
                        <div className="">
                          <i className="fa-brands fa-whatsapp pe-1"></i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-dark mb-1"
                            style={{ height: "20px", width: "25px" }}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <circle cx="18" cy="5" r="3"></circle>
                            <circle cx="6" cy="12" r="3"></circle>
                            <circle cx="18" cy="19" r="3"></circle>
                            <line
                              x1="8.59"
                              y1="13.51"
                              x2="15.42"
                              y2="17.49"
                            ></line>
                            <line
                              x1="15.41"
                              y1="6.51"
                              x2="8.59"
                              y2="10.49"
                            ></line>
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-2" data-aos="zoom-in">
                  <div className="card mx-auto rounded-4">
                    <img
                      src="./assets/images/sflo.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <a
                        href=""
                        className="d-flex justify-content-between align-items-center"
                      >
                        <h6>Agriculture</h6>
                        <div className="">
                          <i className="fa-brands fa-whatsapp pe-2"></i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-dark mb-1"
                            style={{ height: "20px", width: "25px" }}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <circle cx="18" cy="5" r="3"></circle>
                            <circle cx="6" cy="12" r="3"></circle>
                            <circle cx="18" cy="19" r="3"></circle>
                            <line
                              x1="8.59"
                              y1="13.51"
                              x2="15.42"
                              y2="17.49"
                            ></line>
                            <line
                              x1="15.41"
                              y1="6.51"
                              x2="8.59"
                              y2="10.49"
                            ></line>
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-2" data-aos="zoom-in">
                  <div className="card mx-auto rounded-4">
                    <img
                      src="./assets/images/sflo.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <a
                        href=""
                        className="d-flex justify-content-between align-items-center"
                      >
                        <h6>Agriculture</h6>
                        <div className="">
                          <i className="fa-brands fa-whatsapp pe-2"></i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-dark mb-1"
                            style={{ height: "20px", width: "25px" }}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <circle cx="18" cy="5" r="3"></circle>
                            <circle cx="6" cy="12" r="3"></circle>
                            <circle cx="18" cy="19" r="3"></circle>
                            <line
                              x1="8.59"
                              y1="13.51"
                              x2="15.42"
                              y2="17.49"
                            ></line>
                            <line
                              x1="15.41"
                              y1="6.51"
                              x2="8.59"
                              y2="10.49"
                            ></line>
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-2" data-aos="zoom-in">
                  <div className="card mx-auto rounded-4">
                    <img
                      src="./assets/images/sflo.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <a
                        href=""
                        className="d-flex justify-content-between align-items-center"
                      >
                        <h6>Agriculture</h6>
                        <div className="">
                          <i className="fa-brands fa-whatsapp pe-2"></i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-dark mb-1"
                            style={{ height: "20px", width: "25px" }}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <circle cx="18" cy="5" r="3"></circle>
                            <circle cx="6" cy="12" r="3"></circle>
                            <circle cx="18" cy="19" r="3"></circle>
                            <line
                              x1="8.59"
                              y1="13.51"
                              x2="15.42"
                              y2="17.49"
                            ></line>
                            <line
                              x1="15.41"
                              y1="6.51"
                              x2="8.59"
                              y2="10.49"
                            ></line>
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-2" data-aos="zoom-in">
                  <div className="card mx-auto rounded-4">
                    <img
                      src="./assets/images/sflo.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <a
                        href=""
                        className="d-flex justify-content-between align-items-center"
                      >
                        <h6>Agriculture</h6>
                        <div className="">
                          <i className="fa-brands fa-whatsapp pe-2"></i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-dark mb-1"
                            style={{ height: "20px", width: "25px" }}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <circle cx="18" cy="5" r="3"></circle>
                            <circle cx="6" cy="12" r="3"></circle>
                            <circle cx="18" cy="19" r="3"></circle>
                            <line
                              x1="8.59"
                              y1="13.51"
                              x2="15.42"
                              y2="17.49"
                            ></line>
                            <line
                              x1="15.41"
                              y1="6.51"
                              x2="8.59"
                              y2="10.49"
                            ></line>
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-2" data-aos="zoom-in">
                  <div className="card mx-auto rounded-4">
                    <img
                      src="./assets/images/sflo.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <a
                        href=""
                        className="d-flex justify-content-between align-items-center"
                      >
                        <h6>Agriculture</h6>
                        <div className="">
                          <i className="fa-brands fa-whatsapp pe-2"></i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-dark mb-1"
                            style={{ height: "20px", width: "25px" }}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <circle cx="18" cy="5" r="3"></circle>
                            <circle cx="6" cy="12" r="3"></circle>
                            <circle cx="18" cy="19" r="3"></circle>
                            <line
                              x1="8.59"
                              y1="13.51"
                              x2="15.42"
                              y2="17.49"
                            ></line>
                            <line
                              x1="15.41"
                              y1="6.51"
                              x2="8.59"
                              y2="10.49"
                            ></line>
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-2" data-aos="zoom-in">
                  <div className="card mx-auto rounded-4">
                    <img
                      src="./assets/images/sflo.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <a
                        href=""
                        className="d-flex justify-content-between align-items-center"
                      >
                        <h6>Agriculture</h6>
                        <div className="">
                          <i className="fa-brands fa-whatsapp pe-2"></i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-dark mb-1"
                            style={{ height: "20px", width: "25px" }}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <circle cx="18" cy="5" r="3"></circle>
                            <circle cx="6" cy="12" r="3"></circle>
                            <circle cx="18" cy="19" r="3"></circle>
                            <line
                              x1="8.59"
                              y1="13.51"
                              x2="15.42"
                              y2="17.49"
                            ></line>
                            <line
                              x1="15.41"
                              y1="6.51"
                              x2="8.59"
                              y2="10.49"
                            ></line>
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-2" data-aos="zoom-in">
                  <div className="card mx-auto rounded-4">
                    <img
                      src="./assets/images/sflo.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <a
                        href=""
                        className="d-flex justify-content-between align-items-center"
                      >
                        <h6>Agriculture</h6>
                        <div className="">
                          <i className="fa-brands fa-whatsapp pe-2"></i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-dark mb-1"
                            style={{ height: "20px", width: "25px" }}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <circle cx="18" cy="5" r="3"></circle>
                            <circle cx="6" cy="12" r="3"></circle>
                            <circle cx="18" cy="19" r="3"></circle>
                            <line
                              x1="8.59"
                              y1="13.51"
                              x2="15.42"
                              y2="17.49"
                            ></line>
                            <line
                              x1="15.41"
                              y1="6.51"
                              x2="8.59"
                              y2="10.49"
                            ></line>
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-2" data-aos="zoom-in">
                  <div className="card mx-auto rounded-4">
                    <img
                      src="./assets/images/sflo.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <a
                        href=""
                        className="d-flex justify-content-between align-items-center"
                      >
                        <h6>Agriculture</h6>
                        <div className="">
                          <i className="fa-brands fa-whatsapp pe-2"></i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-dark mb-1"
                            style={{ height: "20px", width: "25px" }}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <circle cx="18" cy="5" r="3"></circle>
                            <circle cx="6" cy="12" r="3"></circle>
                            <circle cx="18" cy="19" r="3"></circle>
                            <line
                              x1="8.59"
                              y1="13.51"
                              x2="15.42"
                              y2="17.49"
                            ></line>
                            <line
                              x1="15.41"
                              y1="6.51"
                              x2="8.59"
                              y2="10.49"
                            ></line>
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div className="row row-gap-2 row-gap-sm-3 row-gap-md-4 pt-2 pt-sm-3 pt-md-4">
                <div className="col-md-4 mt-2 fruite" data-aos="zoom-in">
                  <div className="card mx-auto rounded-4 fruite-item">
                    <div className="fruite-img">
                      <img
                        src="./assets/images/sflo.jpg"
                        className="card-img-top"
                        alt="..."
                      />
                    </div>
                    <div className="card-body">
                      <a
                        href=""
                        className="d-flex justify-content-between align-items-center"
                      >
                        <h6>Construction</h6>
                        <div className="">
                          <i className="fa-brands fa-whatsapp pe-1"></i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-dark mb-1"
                            style={{ height: "20px", width: "25px" }}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <circle cx="18" cy="5" r="3"></circle>
                            <circle cx="6" cy="12" r="3"></circle>
                            <circle cx="18" cy="19" r="3"></circle>
                            <line
                              x1="8.59"
                              y1="13.51"
                              x2="15.42"
                              y2="17.49"
                            ></line>
                            <line
                              x1="15.41"
                              y1="6.51"
                              x2="8.59"
                              y2="10.49"
                            ></line>
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-2" data-aos="zoom-in">
                  <div className="card mx-auto rounded-4">
                    <img
                      src="./assets/images/sflo.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <a
                        href=""
                        className="d-flex justify-content-between align-items-center"
                      >
                        <h6>Construction</h6>
                        <div className="">
                          <i className="fa-brands fa-whatsapp pe-2"></i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-dark mb-1"
                            style={{ height: "20px", width: "25px" }}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <circle cx="18" cy="5" r="3"></circle>
                            <circle cx="6" cy="12" r="3"></circle>
                            <circle cx="18" cy="19" r="3"></circle>
                            <line
                              x1="8.59"
                              y1="13.51"
                              x2="15.42"
                              y2="17.49"
                            ></line>
                            <line
                              x1="15.41"
                              y1="6.51"
                              x2="8.59"
                              y2="10.49"
                            ></line>
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-2" data-aos="zoom-in">
                  <div className="card mx-auto rounded-4">
                    <img
                      src="./assets/images/sflo.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <a
                        href=""
                        className="d-flex justify-content-between align-items-center"
                      >
                        <h6>Construction</h6>
                        <div className="">
                          <i className="fa-brands fa-whatsapp pe-2"></i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-dark mb-1"
                            style={{ height: "20px", width: "25px" }}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <circle cx="18" cy="5" r="3"></circle>
                            <circle cx="6" cy="12" r="3"></circle>
                            <circle cx="18" cy="19" r="3"></circle>
                            <line
                              x1="8.59"
                              y1="13.51"
                              x2="15.42"
                              y2="17.49"
                            ></line>
                            <line
                              x1="15.41"
                              y1="6.51"
                              x2="8.59"
                              y2="10.49"
                            ></line>
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-2" data-aos="zoom-in">
                  <div className="card mx-auto rounded-4">
                    <img
                      src="./assets/images/sflo.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <a
                        href=""
                        className="d-flex justify-content-between align-items-center"
                      >
                        <h6>Construction</h6>
                        <div className="">
                          <i className="fa-brands fa-whatsapp pe-2"></i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-dark mb-1"
                            style={{ height: "20px", width: "25px" }}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <circle cx="18" cy="5" r="3"></circle>
                            <circle cx="6" cy="12" r="3"></circle>
                            <circle cx="18" cy="19" r="3"></circle>
                            <line
                              x1="8.59"
                              y1="13.51"
                              x2="15.42"
                              y2="17.49"
                            ></line>
                            <line
                              x1="15.41"
                              y1="6.51"
                              x2="8.59"
                              y2="10.49"
                            ></line>
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-2" data-aos="zoom-in">
                  <div className="card mx-auto rounded-4">
                    <img
                      src="./assets/images/sflo.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <a
                        href=""
                        className="d-flex justify-content-between align-items-center"
                      >
                        <h6>Construction</h6>
                        <div className="">
                          <i className="fa-brands fa-whatsapp pe-2"></i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-dark mb-1"
                            style={{ height: "20px", width: "25px" }}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <circle cx="18" cy="5" r="3"></circle>
                            <circle cx="6" cy="12" r="3"></circle>
                            <circle cx="18" cy="19" r="3"></circle>
                            <line
                              x1="8.59"
                              y1="13.51"
                              x2="15.42"
                              y2="17.49"
                            ></line>
                            <line
                              x1="15.41"
                              y1="6.51"
                              x2="8.59"
                              y2="10.49"
                            ></line>
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-2" data-aos="zoom-in">
                  <div className="card mx-auto rounded-4">
                    <img
                      src="./assets/images/sflo.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <a
                        href=""
                        className="d-flex justify-content-between align-items-center"
                      >
                        <h6>Construction</h6>
                        <div className="">
                          <i className="fa-brands fa-whatsapp pe-2"></i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-dark mb-1"
                            style={{ height: "20px", width: "25px" }}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <circle cx="18" cy="5" r="3"></circle>
                            <circle cx="6" cy="12" r="3"></circle>
                            <circle cx="18" cy="19" r="3"></circle>
                            <line
                              x1="8.59"
                              y1="13.51"
                              x2="15.42"
                              y2="17.49"
                            ></line>
                            <line
                              x1="15.41"
                              y1="6.51"
                              x2="8.59"
                              y2="10.49"
                            ></line>
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-2" data-aos="zoom-in">
                  <div className="card mx-auto rounded-4">
                    <img
                      src="./assets/images/sflo.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <a
                        href=""
                        className="d-flex justify-content-between align-items-center"
                      >
                        <h6>Construction</h6>
                        <div className="">
                          <i className="fa-brands fa-whatsapp pe-2"></i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-dark mb-1"
                            style={{ height: "20px", width: "25px" }}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <circle cx="18" cy="5" r="3"></circle>
                            <circle cx="6" cy="12" r="3"></circle>
                            <circle cx="18" cy="19" r="3"></circle>
                            <line
                              x1="8.59"
                              y1="13.51"
                              x2="15.42"
                              y2="17.49"
                            ></line>
                            <line
                              x1="15.41"
                              y1="6.51"
                              x2="8.59"
                              y2="10.49"
                            ></line>
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-2" data-aos="zoom-in">
                  <div className="card mx-auto rounded-4">
                    <img
                      src="./assets/images/sflo.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <a
                        href=""
                        className="d-flex justify-content-between align-items-center"
                      >
                        <h6>Construction</h6>
                        <div className="">
                          <i className="fa-brands fa-whatsapp pe-2"></i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-dark mb-1"
                            style={{ height: "20px", width: "25px" }}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <circle cx="18" cy="5" r="3"></circle>
                            <circle cx="6" cy="12" r="3"></circle>
                            <circle cx="18" cy="19" r="3"></circle>
                            <line
                              x1="8.59"
                              y1="13.51"
                              x2="15.42"
                              y2="17.49"
                            ></line>
                            <line
                              x1="15.41"
                              y1="6.51"
                              x2="8.59"
                              y2="10.49"
                            ></line>
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-2" data-aos="zoom-in">
                  <div className="card mx-auto rounded-4">
                    <img
                      src="./assets/images/sflo.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <a
                        href=""
                        className="d-flex justify-content-between align-items-center"
                      >
                        <h6>Construction</h6>
                        <div className="">
                          <i className="fa-brands fa-whatsapp pe-2"></i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-dark mb-1"
                            style={{ height: "20px", width: "25px" }}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <circle cx="18" cy="5" r="3"></circle>
                            <circle cx="6" cy="12" r="3"></circle>
                            <circle cx="18" cy="19" r="3"></circle>
                            <line
                              x1="8.59"
                              y1="13.51"
                              x2="15.42"
                              y2="17.49"
                            ></line>
                            <line
                              x1="15.41"
                              y1="6.51"
                              x2="8.59"
                              y2="10.49"
                            ></line>
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex pt-2 pt-sm-3 pt-md-4 justify-content-center">
            <button className="btn btn-round btn-border p-3 px-4 fw-normal">
              See all <i className="fas ps-2 fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default CategoryProduct;
