import React from "react";

const AboutUs = () => {
  return (
    <>
      <section>
        <div className="container">
          <div className="row row-gap-4">
            <div
              className="col-md-6"
              data-aos="fade-right"
              data-aos-duration="500"
              data-aos-delay="100"
            >
              <h3>About Us</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipiscing elit volutpat
                gravida malesuada quam commodo id integer nam. Lorem ipsum dolor
                sit amet consectetur adipiscing elit volutpat gravida malesuada
                quam commodo id integer nam.
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipiscing elit volutpat
                gravida malesuada quam commodo id integer nam.
              </p>
              <button className="btn btn-round btn-border p-3 px-4 fw-normal">
                Read More <i className="fas ps-2 fa-arrow-right"></i>
              </button>
            </div>
            <div
              className="col-md-6"
              data-aos="zoom-in"
              data-aos-duration="500"
              data-aos-delay="100"
            >
              <img
                src="assets/images/About-us-group-img.jpg"
                className="rounded-5 img-fluid w-100"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
