import React from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import AboutUs from "../components/AboutUs";
import Associate from "../components/Associate";
import BlogComponent from "../components/BlogComponent";
import Testimonial from "../components/Testimonial";
import BannerSection from "../components/BannerSection";
import Counter from "../components/Counter";
import OurAim from "../components/OurAim";
import OurValue from "../components/OurValue";
import Team from "../components/Team";
const About = () => {
  return (
    <>
      <Header />
      <BannerSection />
      <Counter />
      <AboutUs />
      <OurAim />
      <OurValue />
      <Team />
      <BlogComponent />
      <Testimonial />
      <Associate />
      <Footer />
    </>
  );
};

export default About;
