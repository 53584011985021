import React from "react";

const Testimonial = () => {
  return (
    <>
      <section>
        <div className="container">
          <h3 className="text-center">What our clients say</h3>
          <p className="text-center">
            Lorem ipsum dolor sit amet consectetur adipiscing elit semper <br />
            dalar elementum tempus hac tellus libero accumsan.
          </p>
          <div
            id="carouselExampleSlidesOnly"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="row">
                  <div className="col-md-4">
                    <div className="card border-0 bg-light">
                      <div className="card-body">
                        <p className="card-text">
                          Some quick example text to build on the card Lorem
                          ipsum dolor sit amet consectetur, adipisicing elit.
                          Temporibus a consectetur in dolorem ea itaque error
                          sed alias aspernatur omnis magni, quidem repudiandae
                          commodi eum veritatis aut distinctio provident
                          laudantium. title and make up the bulk of the card's
                          content.
                        </p>
                        <div className="card-footer d-flex gap-3 bg-transparent border-0 p-0">
                          <img
                            src="assets/images/icon/BG.png"
                            className="w-25"
                            alt=""
                          />
                          <div>
                            <h6>Client Name</h6>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-light"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 d-none d-md-flex">
                    <div className="card border-0 bg-light">
                      <div className="card-body">
                        <p className="card-text">
                          Some quick example text to build on the card Lorem
                          ipsum dolor sit amet consectetur, adipisicing elit.
                          Temporibus a consectetur in dolorem ea itaque error
                          sed alias aspernatur omnis magni, quidem repudiandae
                          commodi eum veritatis aut distinctio provident
                          laudantium. title and make up the bulk of the card's
                          content.
                        </p>
                        <div className="card-footer d-flex gap-3 bg-transparent border-0 p-0">
                          <img
                            src="assets/images/icon/BG.png"
                            className="w-25"
                            alt=""
                          />
                          <div>
                            <h6>Client Name</h6>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-light"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 d-none d-md-flex">
                    <div className="card border-0 bg-light">
                      <div className="card-body">
                        <p className="card-text">
                          Some quick example text to build on the card Lorem
                          ipsum dolor sit amet consectetur, adipisicing elit.
                          Temporibus a consectetur in dolorem ea itaque error
                          sed alias aspernatur omnis magni, quidem repudiandae
                          commodi eum veritatis aut distinctio provident
                          laudantium. title and make up the bulk of the card's
                          content.
                        </p>
                        <div className="card-footer d-flex gap-3 bg-transparent border-0 p-0">
                          <img
                            src="assets/images/icon/BG.png"
                            className="w-25"
                            alt=""
                          />
                          <div>
                            <h6>Client Name</h6>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-light"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="carousel-item">
                <div className="row">
                  <div className="col-md-4">
                    <div className="card border-0 bg-light">
                      <div className="card-body">
                        <p className="card-text">
                          Some quick example text to build on the card Lorem
                          ipsum dolor sit amet consectetur, adipisicing elit.
                          Temporibus a consectetur in dolorem ea itaque error
                          sed alias aspernatur omnis magni, quidem repudiandae
                          commodi eum veritatis aut distinctio provident
                          laudantium. title and make up the bulk of the card's
                          content.
                        </p>
                        <div className="card-footer d-flex gap-3 bg-transparent border-0 p-0">
                          <img
                            src="assets/images/icon/BG.png"
                            className="w-25"
                            alt=""
                          />
                          <div>
                            <h6>Client Name</h6>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-light"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 d-none d-md-flex">
                    <div className="card border-0 bg-light">
                      <div className="card-body">
                        <p className="card-text">
                          Some quick example text to build on the card Lorem
                          ipsum dolor sit amet consectetur, adipisicing elit.
                          Temporibus a consectetur in dolorem ea itaque error
                          sed alias aspernatur omnis magni, quidem repudiandae
                          commodi eum veritatis aut distinctio provident
                          laudantium. title and make up the bulk of the card's
                          content.
                        </p>
                        <div className="card-footer d-flex gap-3 bg-transparent border-0 p-0">
                          <img
                            src="assets/images/icon/BG.png"
                            className="w-25"
                            alt=""
                          />
                          <div>
                            <h6>Client Name</h6>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-light"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 d-none d-md-flex">
                    <div className="card border-0 bg-light">
                      <div className="card-body">
                        <p className="card-text">
                          Some quick example text to build on the card Lorem
                          ipsum dolor sit amet consectetur, adipisicing elit.
                          Temporibus a consectetur in dolorem ea itaque error
                          sed alias aspernatur omnis magni, quidem repudiandae
                          commodi eum veritatis aut distinctio provident
                          laudantium. title and make up the bulk of the card's
                          content.
                        </p>
                        <div className="card-footer d-flex gap-3 bg-transparent border-0 p-0">
                          <img
                            src="assets/images/icon/BG.png"
                            className="w-25"
                            alt=""
                          />
                          <div>
                            <h6>Client Name</h6>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-light"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="carousel-item">
                <div className="row">
                  <div className="col-md-4">
                    <div className="card border-0 bg-light">
                      <div className="card-body">
                        <p className="card-text">
                          Some quick example text to build on the card Lorem
                          ipsum dolor sit amet consectetur, adipisicing elit.
                          Temporibus a consectetur in dolorem ea itaque error
                          sed alias aspernatur omnis magni, quidem repudiandae
                          commodi eum veritatis aut distinctio provident
                          laudantium. title and make up the bulk of the card's
                          content.
                        </p>
                        <div className="card-footer d-flex gap-3 bg-transparent border-0 p-0">
                          <img
                            src="assets/images/icon/BG.png"
                            className="w-25"
                            alt=""
                          />
                          <div>
                            <h6>Client Name</h6>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-light"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 d-none d-md-flex">
                    <div className="card border-0 bg-light">
                      <div className="card-body">
                        <p className="card-text">
                          Some quick example text to build on the card Lorem
                          ipsum dolor sit amet consectetur, adipisicing elit.
                          Temporibus a consectetur in dolorem ea itaque error
                          sed alias aspernatur omnis magni, quidem repudiandae
                          commodi eum veritatis aut distinctio provident
                          laudantium. title and make up the bulk of the card's
                          content.
                        </p>
                        <div className="card-footer d-flex gap-3 bg-transparent border-0 p-0">
                          <img
                            src="assets/images/icon/BG.png"
                            className="w-25"
                            alt=""
                          />
                          <div>
                            <h6>Client Name</h6>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-light"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 d-none d-md-flex">
                    <div className="card border-0 bg-light">
                      <div className="card-body">
                        <p className="card-text">
                          Some quick example text to build on the card Lorem
                          ipsum dolor sit amet consectetur, adipisicing elit.
                          Temporibus a consectetur in dolorem ea itaque error
                          sed alias aspernatur omnis magni, quidem repudiandae
                          commodi eum veritatis aut distinctio provident
                          laudantium. title and make up the bulk of the card's
                          content.
                        </p>
                        <div className="card-footer d-flex gap-3 bg-transparent border-0 p-0">
                          <img
                            src="assets/images/icon/BG.png"
                            className="w-25"
                            alt=""
                          />
                          <div>
                            <h6>Client Name</h6>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-secondary"></i>
                            <i className="fa-solid fa-star text-light"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="carousel-indicators mt-2 m-0 position-static">
              <button
                type="button"
                data-bs-target="#carouselExampleSlidesOnly"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleSlidesOnly"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleSlidesOnly"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonial;
