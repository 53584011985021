import React from "react";

const BlogDetailComponent = () => {
  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-12 my-2" data-aos="fade-up">
                <h5>
                  Tractor engines serve as the core of agricultural strength.
                  Understanding the significance of tractor CC (cubic
                  centimetre) engines is crucial.
                </h5>
              </div>
              <div className="col-xxl-4 col-lg-5 col-md-6" data-aos="fade-up">
                <div className="d-flex my-3 justify-content-between align-items-center">
                  <a
                    href="#"
                    className="text-dark d-flex align-items-center gap-2"
                  >
                    <i className="fa fa-user pe-1"></i>
                    <p className="mb-0">Pawan Kumar</p>
                  </a>
                  <a
                    href="#"
                    className="text-dark d-flex align-items-center gap-2"
                  >
                    <i className="fa fa-clock pe-1"></i>
                    <p className="mb-0">06/01/2024</p>
                  </a>
                  <a
                    href="#"
                    className="text-dark d-flex align-items-center gap-2"
                  >
                    <i className="fa fa-share-alt pe-1"></i>
                  </a>
                </div>
              </div>

              <div className="col-12 my-2" data-aos="fade-up">
                <div className="col-12 py-1 px-4 rounded-3 ligth-div">
                  <p className="my-4 small fw-bold text-dark">
                    The portal was launched by Union Minister Amit Shah. It will
                    facilitate the registration, purchase, and direct payment to
                    farmers producing Tur Dal.​​ Shah emphasized that by January
                    2028, India will no longer need to import any pulses.The
                    portal was launched by Union Minister Amit Shah. It will
                    facilitate the registration, purchase, and direct payment to
                    farmers producing Tur Dal.​​ Shah emphasized that by January
                    2028, India will no longer need to import any pulses.The
                    portal was launched by Union Minister Amit Shah. It will
                    facilitate the registration, purchase, and direct payment to
                    farmers producing Tur Dal.​​ Shah emphasized that by January
                    2028, India will no longer need to import any pulses.
                  </p>
                </div>
              </div>

              <div className="col-12 my-2" data-aos="fade-up">
                <p className="my-4">
                  However, tractor CC indicates the total volume within the
                  engine’s cylinders. It directly influences the tractor’s
                  performance and efficiency. Additionally, it impacts the
                  tractor’s capacity to handle various tasks on the farm. In
                  this blog, we’ll simplify what Tractor CC means and why it’s
                  essential for agricultural machinery.
                </p>
                <p className="my-4">
                  When discussing a tractor engine and its power, we often refer
                  to CC, which stands for cubic centimetres. In a tractor
                  engine, CC represents the engine’s displacement, measuring the
                  total volume all pistons move through. The CC value is crucial
                  for farmers when buying a tractor as it provides an idea of
                  the engine’s capacity. A higher CC typically means a more
                  powerful engine and better performance in the field,
                  especially in heavy-duty tractors. However, CC is just one
                  aspect to consider. Farmers should also consider other factors
                  like HP, PTO, hydraulics, transmission, and more before
                  finalising a tractor purchase.
                </p>
              </div>

              <div className="col-12 my-2" data-aos="fade-up">
                <h4>How Tractor CC Works?</h4>
                <p className="my-4">
                  However, tractor CC indicates the total volume within the
                  engine’s cylinders. It directly influences the tractor’s
                  performance and efficiency. Additionally, it impacts the
                  tractor’s capacity to handle various tasks on the farm. In
                  this blog, we’ll simplify what Tractor CC means and why it’s
                  essential for agricultural machinery.
                </p>
                <div className="text-center my-sm-5 my-2" data-aos="zoom-in">
                  <img
                    src="assets/images/Agri/tractor-2.png"
                    alt=""
                    className="img-fluid rounded-3"
                  />
                </div>
                <p className="my-4">
                  When discussing a tractor engine and its power, we often refer
                  to CC, which stands for cubic centimetres. In a tractor
                  engine, CC represents the engine’s displacement, measuring the
                  total volume all pistons move through. The CC value is crucial
                  for farmers when buying a tractor as it provides an idea of
                  the engine’s capacity. A higher CC typically means a more
                  powerful engine and better performance in the field,
                  especially in heavy-duty tractors. However, CC is just one
                  aspect to consider. Farmers should also consider other factors
                  like HP, PTO, hydraulics, transmission, and more before
                  finalising a tractor purchase.
                </p>
                <p className="my-4">
                  The CC, known as cubic centimetres in a tractor, represents
                  the engine’s displacement, denoting the total volume covered
                  by all cylinders during a combustion cycle. Understanding how
                  tractor CC functions involves comprehending its role in
                  determining engine power and performance.
                </p>
                <p className="my-4">
                  The CC, known as cubic centimetres in a tractor, represents
                  the engine’s displacement, denoting the total volume covered
                  by all cylinders during a combustion cycle. Understanding how
                  tractor CC functions involves comprehending its role in
                  determining engine power and performance.
                </p>
                <p className="my-4">
                  The CC, known as cubic centimetres in a tractor, represents
                  the engine’s displacement, denoting the total volume covered
                  by all cylinders during a combustion cycle. Understanding how
                  tractor CC functions involves comprehending its role in
                  determining engine power and performance.
                </p>
                <p className="my-4">
                  The CC, known as cubic centimetres in a tractor, represents
                  the engine’s displacement, denoting the total volume covered
                  by all cylinders during a combustion cycle. Understanding how
                  tractor CC functions involves comprehending its role in
                  determining engine power and performance.
                </p>
                <p className="my-4">
                  The CC, known as cubic centimetres in a tractor, represents
                  the engine’s displacement, denoting the total volume covered
                  by all cylinders during a combustion cycle. Understanding how
                  tractor CC functions involves comprehending its role in
                  determining engine power and performance.
                </p>
                <p className="my-4">
                  The CC, known as cubic centimetres in a tractor, represents
                  the engine’s displacement, denoting the total volume covered
                  by all cylinders during a combustion cycle. Understanding how
                  tractor CC functions involves comprehending its role in
                  determining engine power and performance.
                </p>
                <p className="my-4">
                  The CC, known as cubic centimetres in a tractor, represents
                  the engine’s displacement, denoting the total volume covered
                  by all cylinders during a combustion cycle. Understanding how
                  tractor CC functions involves comprehending its role in
                  determining engine power and performance.
                </p>
                <p className="my-4">
                  The CC, known as cubic centimetres in a tractor, represents
                  the engine’s displacement, denoting the total volume covered
                  by all cylinders during a combustion cycle. Understanding how
                  tractor CC functions involves comprehending its role in
                  determining engine power and performance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetailComponent;
