import React from "react";

const Subscribe = () => {
  return (
    <>
      <section id="subscribe-now">
        <div className="container" data-aos="fade-up">
          <div className="d-flex justify-content-around align-items-center">
            <div className="d-flex flex-column w-75 justify-content-between gap-1 gap-sm-2 gap-md-3">
              <div className="banner-heading">
                Are you want to more update about Agriculture and Construction
                hardware
              </div>
              <div className="d-flex gap-1 gam-sm-2 gap-md-4">
                <input
                  type="text"
                  placeholder="Email address"
                  className="rounded-pill w-75 banner-input flex-md-grow-1"
                />
                <button className="btn btn-primary mini-btn btn-round text-nowrap">
                  Subscibe now
                </button>
              </div>
            </div>
            <div className="">
              <img src="assets/images/icon/bell.png" className="" alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Subscribe;
