import React from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import BannerSection from "../components/BannerSection";
import ContactComponent from "../components/ContactComponent";
import OfficeAddress from "../components/OfficeAddress";
import Associate from "../components/Associate";

const Contact = () => {
  return (
    <>
      <Header />
      <BannerSection />
      <ContactComponent />
      <OfficeAddress />
      <Associate />
      <Footer />
    </>
  );
};

export default Contact;
