import React from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import MainSection from "../components/MainSection";
import AboutUs from "../components/AboutUs";
import CategoryProduct from "../components/CategoryProduct";
import Associate from "../components/Associate";
import BlogComponent from "../components/BlogComponent";
import Contact from "../components/Contact";
import Testimonial from "../components/Testimonial";
const Home = () => {
  return (
    <>
      <Header />
      <MainSection />
      <AboutUs />
      <CategoryProduct />
      <Associate />
      <BlogComponent />
      <Contact />
      <Testimonial />
      <Footer />
    </>
  );
};

export default Home;
